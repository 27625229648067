<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="this.Tool.is_auth('systemset.user.issave')" icon="el-icon-plus"
                                   size="medium" type="primary" @click="tosee()">
                            <span v-if="count===0">开通品牌主页</span>
                            <span v-else>新建品牌</span>
                        </el-button>
                    </div>

                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    size="medium"
                    border
                    v-loading="loading"
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        prop="actual_name"
                        label="品牌负责人姓名"
                        width="120"
                        source_channel>
                </el-table-column>
                <el-table-column
                        prop="phone"
                        label="负责人手机号"
                        width="120"
                        source_channel>
                </el-table-column>
                <el-table-column
                        prop="card_front"
                        label="身份证"
                        width="80">
                    <template slot-scope="scope">
                        <div>
                            <el-image
                                    style="width: 22px; height: 22px; margin-right: 4px;"
                                    :src="scope.row.card_front"
                                    fit="contain"
                                    :preview-src-list="[scope.row.card_front]">
                            </el-image>
                            <el-image
                                    style="width: 22px; height: 22px; margin-right: 4px;"
                                    :src="scope.row.card_back"
                                    fit="contain"
                                    :preview-src-list="[scope.row.card_back]">
                            </el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="brand_logo"
                        label="品牌LOGO">
                    <template slot-scope="scope">
                        <div>
                            <el-image
                                    style="width: 22px; height: 22px; margin-right: 4px;"
                                    :src="scope.row.brand_logo"
                                    fit="contain"
                                    :preview-src-list="[scope.row.brand_logo]">
                            </el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="brand_photo"
                        label="商标证书">
                    <template slot-scope="scope">
                        <div>
                            <el-image
                                    style="width: 22px; height: 22px; margin-right: 4px;"
                                    :src="scope.row.brand_photo"
                                    fit="contain"
                                    :preview-src-list="[scope.row.brand_photo]">
                            </el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="state"
                        label="状态"
                        width="180"
                        show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.state===0" type="info">未审核</el-tag>
                        <el-tag size="mini" v-if="scope.row.state===1" type="success">
                            通过{{ scope.row.audit_time }}
                        </el-tag>
                        <el-tag size="mini" v-if="scope.row.state===2" type="danger">拒绝</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="create_time"
                        label="创建时间"
                        width="120"
                        show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        width="110">
                    <template slot-scope="scope">
                        <el-button
                                v-if="is_auth('approve.brand.getinfo')"
                                @click="tosee(scope.row.certified_uuid)"
                                size="mini">查看详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="this.count">
            </el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '品牌列表',
            issearch: true,
            loading: true,
            search: {},         // 搜索条件
            tableData: [],      // 列表内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数

        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },// 初始化
        init() {
            this.search = {}
            this.is_search();
        },
        // 搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        //列表详情
        getlist() {
            let postdata = {
                api_name: "approve.brand.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);

            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.$message.error(json.message);
                }
            })
        },
        // 查看
        tosee(certified_uuid = '') {
            if (certified_uuid === '') {
                this.$router.push({path: '/brand/brand/details'})
            } else {
                this.$router.push({path: '/brand/brand/details', query: {certified_uuid}})
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
